import React from "react"

const MetroFaresPrimary = () => (
  <>
    <h2 style={{ margin: '0 0 20px 0'}}>24/25 fares</h2>
    <p>Payment options on this service:</p>
    <ul>
      <li><strong>Cash/contactless</strong>. The fare is £1.00 each way or £8 for a weekly ticket. Try to bring the exact change. Passengers <strong>MUST</strong> be registered on this service to travel. Please apply via the metro website: <a href="https://www.wymetro.com/schools/applications/mybus-primary-school/primary-school-application-forms/">Application form</a></li>
      <li>You can speak with the school transport team at Metro by calling 0113 348 1122.</li>
    </ul>
  </>
)

export default MetroFaresPrimary